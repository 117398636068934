@import "../../styles/color-palette";

.social_buttons {
  display: flex;

  &-items {
    list-style: none;

    & a {
      display: block;
      position: relative;
      width: 50px;
      height: 50px;
      line-height: 80px;
      text-align: center;
      text-decoration: none;
      color: $color-light-grey;
      margin: 0 10px;
      transition: 0.5s;

      & span {
        position: absolute;
        transition: transform 0.5s;
      }

      & span:nth-child(1),
      & span:nth-child(3) {
        width: 100%;
        height: 3px;
        background: $color-light-grey;
      }

      & span:nth-child(1) {
        top: 0;
        left: 0;
        transform-origin: right;
      }

      &:hover span:nth-child(1) {
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.5s;
      }

      & span:nth-child(3) {
        bottom: 0;
        left: 0;
        transform-origin: left;
      }

      &:hover span:nth-child(3) {
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.5s;
      }

      & span:nth-child(2),
      & span:nth-child(4) {
        width: 3px;
        height: 100%;
        background: $color-light-grey;
      }

      & span:nth-child(2) {
        top: 0;
        left: 0;
        transform: scale(0);
        transform-origin: bottom;
      }

      &:hover span:nth-child(2) {
        transform: scale(1);
        transform-origin: top;
        transition: transform 0.5s;
      }

      & span:nth-child(4) {
        top: 0;
        right: 0;
        transform: scale(0);
        transform-origin: top;
      }

      &:hover span:nth-child(4) {
        transform: scale(1);
        transform-origin: bottom;
        transition: transform 0.5s;
      }
    }

    & .linkedIn:hover {
      color: #0072b1;
    }

    & .linkedIn:hover span {
      background: #0072b1;
    }

    & .facebook:hover {
      color: #3b5998;
    }

    & .facebook:hover span {
      background: #3b5998;
    }

    & .twitter:hover {
      color: #1da1f2;
    }

    & .twitter:hover span {
      background: #1da1f2;
    }

    & .mail:hover {
      color: #dd4b39;
    }

    & .mail:hover span {
      background: #dd4b39;
    }

    & .icon {
      font-size: 3.5rem;
    }
  }
}
