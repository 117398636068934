.pageHost {
  height: 100vh;
  overflow: auto;
  // border: 5px dotted dodgerblue;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  justify-content: space-between;
  position: relative;

  & .content {
    width: 100%;
    // margin: 5rem 0;
    // border: 1px solid red;

    & > * {
      margin: 0 auto;
    }
  }

  & .logoContainer {
    min-height: 11rem;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    & .text {
      color: #fff;
      font-size: 2rem;
    }
  }

  & .footer {
    min-height: 9rem;
    margin-top: 3rem;
    display: flex;
    align-items: center;

    & .text {
      color: #fff;
      font-size: 2rem;
    }
  }
}
