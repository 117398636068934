@import "../../styles/color-palette";

.HomeCard {
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-direction: column;
  position: relative;
  // height: 100%;
  // border: 1px solid white;

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: 1px solid orange;
    // align-self: flex-start;
    // width: 20rem;
    // height: 20rem;

    & .text {
      font-size: 4.5rem;
      color: $color-base-white;
      text-align: center;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    }

    & .link {
      color: dodgerblue;
      font-size: 2rem;
      text-decoration: none;
      display: flex;
      align-items: center;

      & .arrow {
        font-size: 2.5rem;
      }
    }

    & .icon {
      font-size: 7.5rem;
      color: $color-dodger-blue;

      &.swing {
        animation: swing ease-in-out 0.7s infinite alternate;
        transform-origin: center -20px;
        float: left;
      }

      &.swing:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        border: 1px solid #999;
        top: -10px;
        left: 50%;
        z-index: 0;
        border-bottom: none;
        border-right: none;
        transform: rotate(45deg);
      }

      &.swing:before {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        top: -14px;
        left: 54%;
        z-index: 5;
        border-radius: 50% 50%;
        background: #000;
      }
    }
  }

  & .socialList {
    position: absolute;
    bottom: -5rem;
    right: 0;
    // display: flex;
  }
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
