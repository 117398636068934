@import "../../styles/color-palette";

.InfoCard {
  display: flex;
  justify-content: center;
  align-items: center;

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;

    & .text {
      color: $color-base-white;
      font-size: 4.5rem;
      text-align: center;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    }

    & .icon {
      font-size: 7.5rem;
      color: $color-dodger-blue;
    }

    & .link {
      color: dodgerblue;
      font-size: 2rem;
      text-decoration: none;
      display: flex;
      align-items: center;

      & .arrow {
        font-size: 2.5rem;
      }
    }
  }

  & .socialList {
    position: absolute;
    bottom: -5rem;
    right: 0;
    // display: flex;
  }
}
