.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  // border: 1px solid red;

  &.horizontal {
    flex-direction: row;
    // flex-wrap: wrap;
  }

  &.vertical {
    flex-direction: column;
  }

  &.grow > * {
    flex-grow: 1;
  }

  &.flexWrap {
    flex-wrap: wrap;
  }

  &.start {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.stretch {
    align-items: stretch;
  }

  &.end {
    align-items: flex-end;
  }
}
